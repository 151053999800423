<template>
  <div class="manage-subcription">
    <c-box mb="8" d="flex" justify-content="space-between">
      <div>
        <c-heading
          :fontSize="{ base: 'sm', lg: '2xl', xl: '4xl' }"
          as="h1"
          display="flex"
          alignItems="baseline"
          mb="4"
        >
          <c-text mr="3"> Buy AI Credits</c-text>
        </c-heading>
        <c-box>
          <c-text fontSize="1em"
            >Buy AI points to help your business with AI assistance. The more
            points you have, the better and faster responses and
            suggestions.</c-text
          >
        </c-box>
      </div>

      <c-box>
        <c-button size="md" px="8" variant-color="vc-orange" variant="outline">
          Credits {{ getPoints }}
        </c-button>
      </c-box>
    </c-box>

    <div class="d">
      <div class="plan">
        <div style="text-align: center" v-if="aiCredits.length == 0">
          <c-spinner color="#EF5323" />
        </div>
        <div v-else>
          <c-pseudo-box
            v-for="(credit, ix) in aiCredits"
            :key="ix"
            px="4"
            py="3"
            mb="3"
            w="100%"
            box-shadow="md"
            rounded="md"
            v-chakra
            :_hover="{
              border: '1px',
              borderColor: '#ef5323',
              cursor: 'pointer',
            }"
            :class="
              selectedCredit.description === credit.description
                ? 'plan_card_active'
                : 'plan_card'
            "
            @click="selectedCredit = credit"
          >
            <c-text fontWeight="bold" mb="3" fontSize="md" color="#ef5323">{{
              credit.description
            }}</c-text>
            <c-flex justify-content="space-between">
              <div>
                <c-text fontWeight="bold" fontSize="md"
                  >${{ credit.amount }}</c-text
                >
                <c-text fontSize="xs" mb="1"
                  >{{ credit.quantity }} AI Credits</c-text
                >
              </div>

              <svg
                v-if="selectedCredit.description === credit.description"
                v-chakra="{
                  width: '18px',
                  height: '18px',
                  ml: '2',
                  color: 'vc-orange.300',
                }"
              >
                <use href="@/assets/icons/check-circle.svg#check"></use>
              </svg>
            </c-flex>
          </c-pseudo-box>
        </div>
      </div>
      <div class="plan_details">
        <c-grid-item>
          <c-box mb="4">
            <c-text px="2" py="3" mb="1" fontSize="md" fontWeight="bold">
              Payment Information</c-text
            >
            <div style="text-align: center" v-show="isLoadingCards">
              <c-spinner color="#EF5323" />
            </div>

            <c-grid
              w="100%"
              px="2"
              template-columns="repeat(12, 1fr)"
              gap="4"
              v-show="!isLoadingCards"
            >
              <c-grid-item col-span="4">
                <c-box>
                  <label for>Card Details</label>
                  <div id="cc" ref="cad"></div>
                </c-box>
              </c-grid-item>
              <c-grid-item v-if="activeCard.length !== 0" col-span="5">
                <c-box>
                  <label>.</label>
                  <div>
                    <c-button
                      @click="submitPaymentMethod"
                      size="md"
                      px="8"
                      variant-color="vc-orange"
                      variant="outline"
                    >
                      Save New Card
                      <c-text ml="1" fontSize="1.5em" color="#ef5323">+</c-text>
                    </c-button>
                  </div>
                </c-box>
              </c-grid-item>

              <c-grid-item v-if="activeCard.length === 0" col-span="4">
                <c-box>
                  <label for>Full Name</label>
                  <c-input
                    v-model="form.name"
                    placeholder="Enter Full Name"
                    type="text"
                  />
                </c-box>
              </c-grid-item>
              <c-grid-item v-if="activeCard.length === 0" col-span="4">
                <c-box>
                  <label for>Email Address</label>
                  <c-input
                    v-model="form.email"
                    placeholder="Enter Email Address"
                    type="email"
                  />
                </c-box>
              </c-grid-item>

              <c-grid-item v-if="activeCard.length === 0" col-span="4">
                <c-box>
                  <label for>Phone Number</label>
                  <c-input
                    v-model="form.phone"
                    placeholder="Enter Phone Number"
                    type="number"
                  />
                </c-box>
              </c-grid-item>

              <c-grid-item v-if="activeCard.length === 0" col-span="4">
                <c-box>
                  <c-box>
                    <c-button
                      @click="saveCustomer"
                      size="md"
                      px="8"
                      mt="5"
                      :isLoading="saveBtnLoading"
                      variant-color="vc-orange"
                      :disable="activeCard.length === 0"
                    >
                      Save
                    </c-button>
                  </c-box>
                </c-box>
              </c-grid-item>
            </c-grid>
          </c-box>
          <c-box>
            <c-box
              d="flex"
              flex-direction="column"
              mb="3"
              w="100%"
              box-shadow="md"
              rounded="md"
            >
              <c-text px="4" py="3" mb="3" fontSize="md" fontWeight="bold">
                Billing Information</c-text
              >

              <c-box mx="4" my="4" border="1px" borderColor="gray.200">
                <c-box px="5" py="3" v-if="activeCard.length !== 0">
                  <c-box>
                    <c-text mb="3" fontSize="sm" fontWeight="semibold"
                      >Payment card</c-text
                    >
                    <c-box
                      display="flex"
                      rounded="lg"
                      align-items="center"
                      my="4"
                      w="50%"
                      bg="#F4F6FC"
                    >
                      <c-box
                        backgroundSize="cover"
                        bgRepeat="no-repeat"
                        backgroundPosition="center center"
                        rounded="md"
                        h="100%"
                        mr="2"
                        py="2"
                        pl="2"
                        w="3em"
                      >
                        <img
                          v-if="activeCard.brand === 'visa'"
                          class="pl"
                          v-chakra
                          w="90%"
                          src="@/assets/img/visa-img.png"
                          alt="Card Type - Visa"
                        />
                        <img
                          v-else
                          class=""
                          v-chakra
                          w="90%"
                          src="@/assets/img/mastercard-img.png"
                          alt="Card Type - Mastercard"
                        />
                      </c-box>
                      <c-text
                        diplay="flex"
                        align-items="center"
                        justify="center"
                        color="gray.500"
                      >
                        **** {{ activeCard.last4 }}
                      </c-text>
                    </c-box>
                  </c-box>

                  <c-grid mb="3" template-columns="repeat(12, 1fr)" gap="6">
                    <c-grid-item col-span="3">
                      <c-button
                        @click="showCards = !showCards"
                        size="md"
                        px="8"
                        w="100%"
                        variant-color="vc-orange"
                        variant="outline"
                      >
                        Change card
                        <svg v-chakra fill="#ef5323" w="6" h="6" ml="1">
                          <use
                            href="@/assets/icons/icon-refresh.svg#refresh"
                          ></use>
                        </svg>
                      </c-button>
                    </c-grid-item>
                  </c-grid>
                </c-box>

                <c-box bg="#F4F6FC" mt="3" px="5" py="3" v-if="showCards">
                  <c-grid mb="3" template-columns="repeat(5, 1fr)" gap="6">
                    <c-grid-item col-span="3">
                      <c-text mb="3" fontSize="md" fontWeight="semibold"
                        >Recent Cards</c-text
                      >

                      <c-box
                        w="100%"
                        bg="white"
                        d="flex"
                        align-items="center"
                        justify-content="space-between"
                        h="3em"
                        py="2"
                        px="3"
                        mb="3"
                        rounded="md"
                        v-for="(card, index) in cards"
                        :key="index"
                      >
                        <c-box d="flex" align-items="center">
                          <div v-if="card.IsActive" d="flex">
                            <div
                              class="active"
                              @click="defaultCard(card)"
                            ></div>
                          </div>
                          <c-text v-else>
                            <div
                              class="not_active"
                              @click="defaultCard(card)"
                            ></div>
                          </c-text>

                          <c-box
                            backgroundSize="cover"
                            bgRepeat="no-repeat"
                            backgroundPosition="center center"
                            rounded="md"
                            h="100%"
                            mr="2"
                            w="3em"
                          >
                            <img
                              v-if="card.brand === 'visa'"
                              class=""
                              v-chakra
                              w="90%"
                              src="@/assets/img/visa-img.png"
                              alt="Card Type - Visa"
                            />
                            <img
                              v-else
                              class=""
                              v-chakra
                              w="90%"
                              src="@/assets/img/mastercard-img.png"
                              alt="Card Type - Mastercard"
                            />
                          </c-box>

                          <c-text>**** {{ card.last4 }}</c-text>
                        </c-box>

                        <svg
                          @click="deleteCard(card)"
                          fill="vc-orange.300"
                          v-chakra="{
                            cursor: 'pointer',
                            width: '12px',
                            height: '12px',
                          }"
                        >
                          <use
                            href="@/assets/icons/minus-box-fill.svg#minus"
                          ></use>
                        </svg>
                      </c-box>

                      <c-text
                        font-size=".7em"
                        v-if="cards.length === 0 && !isLoadingCards"
                        >No cards added yet</c-text
                      >
                      <ContentLoader
                        v-if="isLoadingCards"
                        viewBox="0 0 250 130"
                        primaryColor="#ddd"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="150"
                          height="10"
                        />
                      </ContentLoader>
                    </c-grid-item>
                  </c-grid>
                </c-box>
              </c-box>
            </c-box>
            <c-box bg="white" mb="3" w="100%" box-shadow="md" rounded="md">
              <c-box d="flex" justify-content="space-between">
                <c-text px="4" py="3" mb="1" fontSize="md" fontWeight="bold">
                  Billing Summary</c-text
                >

                <c-box d="flex" align-items="center">
                  <c-text mr="2" fontSize="xs" d="flex" color="black"
                    >Billing Cycle: One Time
                  </c-text>
                  <label class="toggle-button">
                    <input type="checkbox" :checked="true" />
                    <div class="toggle-bg"></div>
                    <c-text mx="2" fontSize="xs" d="flex" color="#ef5323"
                      >{{ selectedCredit.description }}
                    </c-text>
                  </label>
                </c-box>
              </c-box>
              <c-box px="4" py="3" borderTop="1px" borderColor="gray.200">
                <c-grid w="100%" template-columns="repeat(2, 1fr)" gap="1">
                  <c-box fontSize="sm">
                    <c-text color="black" fontWeight="500"
                      >{{ selectedCredit.quantity }} AI Credits</c-text
                    >
                  </c-box>

                  <c-box textAlign="right" h="10">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >${{ selectedCredit.amount }}</c-text
                    >
                  </c-box>
                  <c-box fontSize="sm">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >Sales tax</c-text
                    >
                  </c-box>
                  <c-box textAlign="right" h="10">
                    <c-text fontSize="sm" color="gray.400">$0.00</c-text>
                  </c-box>
                </c-grid>
              </c-box>
              <c-box px="4" py="3" borderTop="1px" borderColor="gray.200">
                <c-grid w="100%" template-columns="repeat(2, 1fr)" gap="6">
                  <c-box fontSize="sm">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >Total</c-text
                    >
                  </c-box>

                  <c-box textAlign="right" h="10">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >${{ totalCost }}</c-text
                    >
                  </c-box>
                </c-grid>
              </c-box>
              <c-box px="4" py="3">
                <c-box mb="3">
                  <c-checkbox v-model="agreeToPlan" fontSize="xs"
                    >I agree to the Terms of Service and acknowledge the Privacy
                    Policy</c-checkbox
                  >
                </c-box>
                <c-button
                  :disabled="!agreeToPlan"
                  @click="buyAiCredits"
                  size="md"
                  px="8"
                  variant-color="vc-orange"
                  :isLoading="btnLoading"
                >
                  Buy {{ selectedCredit.description }}
                </c-button>
              </c-box>
            </c-box>

            <c-box mb="3" w="100%" box-shadow="md" rounded="md">
              <c-button
                @click="showBillingHistory = true"
                size="md"
                px="8"
                w="100%"
                variant-color="vc-orange"
              >
                Show Billing history
              </c-button>
            </c-box>
          </c-box>
        </c-grid-item>
      </div>
    </div>

    <c-modal :is-open="showSubscribeModal" :closeOnOverlayClick="false">
      <c-modal-content ref="content">
        <c-modal-header>Thank you for Purchasing Credits</c-modal-header>
        <c-modal-body>
          <c-box display="flex" align-items="center" justify-content="center">
            <p>
              You have successfully purchased
              {{ selectedCredit.quantity }} AI Credits. You can now enjoy the
              benefits of AI suggesting and content generation.
            </p>
          </c-box>
        </c-modal-body>
        <c-modal-footer>
          <c-button variant-color="vc-orange" mr="3" @click="gotoApp">
            Start Using AI
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-modal :is-open="showBillingHistory" :closeOnOverlayClick="true">
      <c-modal-content ref="content">
        <c-modal-header>Billing History</c-modal-header>
        <c-modal-body>
          <BillingHistory />
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BillingHistory from './BillingHistory.vue';
import {
  addCard,
  getCards,
  deleteCard,
  defaultCard,
  createCustomer,
  buyCredits,
  getClientSecret,
  getCreditCharges,
} from '../../../services/subscription';
import { ContentLoader } from 'vue-content-loader';
import { loadStripe } from '@stripe/stripe-js';

export default {
  components: {
    ContentLoader,
    BillingHistory,
  },
  data() {
    return {
      showBillingHistory: false,
      btnLoading: false,
      showSubscribeModal: false,
      history: [],
      token: null,
      card: '',
      agreeToPlan: false,
      isAddingCard: false,
      hasASubscription: false,
      publishableKey:
        'pk_test_51L5BIlDpNBuvEcjKIvja9t2j6abTRMZpArz6B11rXmninREOAZG6NoOzG7I10Q9UHKiKbTDYURY6zvLSZAPaEV6Q00TBF5qAQ3',
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: '',
      },
      saveBtnLoading: false,
      showCards: false,
      isEndPlanOpen: false,
      isLoadingCards: true,
      isLoadingHistory: true,
      selectedCredit: {},
      form: {
        name: '',
        phone: '',
        email: '',
        coupon: '',
      },
      stripe: '',
      aiCredits: [],
      cards: [],
      activeCard: [],
    };
  },
  mounted() {
    this.generatePaymentIntent();
    this.getAIcredits();
    this.example();
    this.getCards();
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    totalCost() {
      return this.selectedCredit.amount;
    },
    getPoints() {
      return this.activeCompany.aiCreditCount;
    },
  },
  methods: {
    async generatePaymentIntent() {
      const cs = await getClientSecret();
      this.elementsOptions.clientSecret = cs.data.data.clientSecret;
    },
    async example() {
      let strip = await loadStripe(this.publishableKey);
      this.stripe = strip;
      let elements = strip.elements({
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
          },
        ],
      });
      this.card = elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            lineHeight: '40px',
            fontSize: '1.1rem',
            fontFamily: '"Montserrat",sans-serif',
            color: '#1A202C',

            '::placeholder': {
              color: '#A0AEC0',
            },
          },
        },
      });
      this.card.mount(this.$refs.cad);
    },
    getCards() {
      this.isLoadingCards = true;
      getCards()
        .then((res) => {
          this.cards = res.data.data;

          this.activeCard = this.cards.find((card) => card.IsActive);
        })

        .finally(() => {
          this.isLoadingCards = false;
        });
    },
    submitPaymentMethod() {
      this.stripe
        .confirmCardSetup(this.elementsOptions.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              email: 'charlesprogram@gmail.com',
            },
          },
        })

        .then((res) => {
          console.log(res);
          if (res.error) {
            this.$toast({
              title: 'An error occurred.',
              description: `Error adding billing card. ${res.error.message}`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          } else {
            this.getCards();
            this.$toast({
              title: 'Success.',
              description: `New billing card added successfully.`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        });
    },
    handleAddCard() {
      this.isAddingCard = true;
      this.$refs.elementRef.submit();
      // createCustomer({
      //   email: this.user.email,
      //   name: `${this.user.firstname} ${this.user.lastname}`,
      // }).then((res) => {
      //   console.log(res);
      // });
    },
    async buyAiCredits() {
      this.btnLoading = true;

      if (this.addedCustomer || !this.noActiveCard) {
        await this.buyCreditPoints();
      }

      if (this.noActiveCard && !this.addedCustomer) {
        await this.submitPaymentMethod();
        await this.buyCreditPoints();
      }
    },

    buyCreditPoints() {
      const companyId = this.activeCompany.id;
      const payload = {
        chargeType: 1,
        companyId: this.activeCompany.id.toString(),
        chargeId: 1,
      };

      buyCredits(companyId, payload)
        .then((res) => {
          console.log(res);
          this.$toast({
            title: 'Success',
            description: `Credit Payment successfull`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.btnLoading = false;
          this.showSubscribeModal = true;
        })
        .catch((error) => {
          const err = Object.assign({}, error);
          const { message } = err.response.data;

          this.btnLoading = false;
          this.$toast({
            title: 'An error occurred.',
            description: `${message}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    saveCustomer() {
      this.saveBtnLoading = true;
      createCustomer({
        email: this.form.email,
        name: this.form.name,
        phone: this.form.phone,
      })
        .then(() => {
          this.saveBtnLoading = false;
          this.addedCustomer = true;
          // this.$refs.elementRef.submit();
          this.submitPaymentMethod();
          this.$toast({
            title: 'Saved.',
            description: `Customer account created successfully.`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.saveBtnLoading = false;
          this.$toast({
            title: 'An error occurred.',
            description: `Customer details failed to save.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    deleteCard(card) {
      const cardIndex = this.cards.indexOf(card);
      deleteCard({ cardPaymentId: card.cardId })
        .then(() => {
          this.$toast({
            title: 'Success!!!',
            description: `Card has been deleted`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.cards.splice(cardIndex, 1);
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Can not remove default card.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    defaultCard(card) {
      // const cardIndex = this.cards.indexOf(card);
      // this.cards.splice(cardIndex, 1);
      this.isLoadingCards = true;
      defaultCard({ cardPaymentId: card.cardId })
        .then((res) => {
          this.$toast({
            title: 'Success!!!',
            description: `Billing card changed successfully`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          console.log(res.data);
          this.getCards();
        })
        .catch(() => {
          // this.cards.splice(cardIndex, 0, card);

          this.$toast({
            title: 'An error occurred.',
            description: `Please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    addCard(token) {
      const set = {
        expiredDate: `${token.card.exp_year}-${token.card.exp_month}-01`,
        name: token.card.name || '',
        last4: token.card.last4,
        bin: token.card.bin || '',
        token: token.id,
        userId: this.user.id,
      };

      addCard(set)
        .then(() => {
          this.$toast({
            title: 'Success!!!',
            description: `Card has been added`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Could not add card, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
          // this.isAddingCard = false;
        })
        .finally(() => {
          this.isAddingCard = false;
        });
    },
    payWithNewCard() {
      // this.isAddingCard = true;
      if (this.cards.length === 0) {
        // this.showCards = true;
        this.$toast({
          title: 'Info.',
          description: `Could not proceed with payment, please try adding a payment method.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    getAIcredits() {
      getCreditCharges('1')
        .then((res) => {
          const credits = res.data.credit_charges;
          // map and divide by 100 to get the actual amount
          credits.map((credit) => {
            credit.amount = credit.amount / 100;
            return credit;
          });

          this.selectedCredit = credits[0];

          this.aiCredits = credits;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gotoApp() {
      this.$router.push({
        path: `/app/overview`,
      });
    },
    formatDate(date) {
      return date && this.$moment(date).isValid()
        ? this.$moment(date).format('YYYY-MM-DD')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.plan_card {
  background-color: white;
}
.plan_card_active {
  border: 1px solid #ef5323;
  background-color: #f4f6fc;
}
.toggle-button {
  @apply flex items-center cursor-pointer relative;

  .toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-4 w-5 transition shadow-sm;
  }

  input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
  }

  input:checked + .toggle-bg {
    @apply bg-secondary border-secondary;
  }

  input {
    @apply sr-only;
  }

  div {
    @apply bg-gray-200 border-2 border-gray-200 h-4 w-10 rounded-full;
  }
}

label:not(.toggle-button) {
  font-size: 0.8em;
}
.d {
  @apply grid grid-cols-12 gap-3;
}
.plan {
  @apply col-span-4;
}
.plan_details {
  @apply col-span-8;
}
.not_active {
  @apply h-4 w-4 mx-2 rounded-full border border-secondary cursor-pointer hover:shadow-xl;
}
.active {
  @apply h-4 w-4 mx-2 rounded-full border cursor-pointer  hover:shadow-xl;
  background-color: green;
  border-color: green;
}
#cc {
  @apply border border-solid bg-white px-4  rounded-md;
}

.css-kfzfjk {
  border-radius: 10px;
}

button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
